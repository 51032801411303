<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page

========================================================================================== -->

<template>
  <div id="page-bank-view">
    <vs-alert v-if="errorOccurred" color="danger" title="Bank Not Found">
      <span>Bank record with id: not found. </span>
      <span>
        <span>Check </span><router-link :to="{ name: 'admin-list-examedge-bank' }" class="text-inherit underline">All Banks</router-link>
      </span>
    </vs-alert>

    <div v-if="bankData" id="bank-data">
      <!--- TOP SET BANNER START --->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <div
            class="rounded-lg w-full main-border"
            style="
              background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/global-banner.png);
              box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
              background-size: cover;
            "
          >
            <div class="knowledge-base-jumbotron-content rounded-lg lg:p-12 sm:p-10 p-8">
              <div class="flex justify-between items-center">
                <div>
                  <h1 class="text-white w-full" style="font-size: 4rem">{{ bankData.name }}</h1>
                </div>
                <img class="rounded" :src="bankData.thumbnail" v-if="bankData.thumbnail" width="150px" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--- TOP SET BANNER END --->

      <!--- SET ACTION BAR START --->
      <div class="vx-row mb-6 match-height">
        <div class="vx-col grid grid-cols-3 gap-8 w-full">
          <vx-card
            class="p-6 cursor-pointer"
            @click="editBankPopup = !editBankPopup"
            :style="`background-image: linear-gradient(118deg, rgba(var(--vs-success), 0.4), rgba(var(--vs-success), .2)), url(${cardBgImgURL})`"
          >
            <div class="flex items-center justify-center">
              <feather-icon icon="EditIcon" svgClasses="h-16 w-16" />
            </div>
            <div class="flex items-center justify-center">
              <h2 class="text-center mt-4 text-white">Edit<br />Bank</h2>
            </div>
          </vx-card>
          <vx-card
            class="p-6 cursor-pointer text-white"
            @click="$router.push(`/a/examedge/assignments?bank=${bankData.id}`)"
            :style="`background-image: linear-gradient(118deg, rgba(var(--vs-warning), 0.4), rgba(var(--vs-warning), .2)), url(${cardBgImgURL})`"
          >
            <div class="flex items-center justify-center">
              <feather-icon icon="FileTextIcon" svgClasses="h-16 w-16" />
            </div>
            <div class="flex items-center justify-center">
              <h2 class="text-center mt-4 text-white">Open<br />Assignments</h2>
            </div>
          </vx-card>

          <vx-card
            class="p-6 cursor-pointer text-white"
            @click="confirmDeleteBank"
            :style="`background-image: linear-gradient(118deg, rgba(var(--vs-danger), 0.4), rgba(var(--vs-danger), .2)), url(${cardBgImgURL})`"
          >
            <div class="flex items-center justify-center">
              <feather-icon icon="TrashIcon" svgClasses="h-16 w-16" />
            </div>
            <div class="flex items-center justify-center">
              <h2 class="text-center mt-4 text-white">Archive<br />Bank</h2>
            </div>
          </vx-card>
        </div>
      </div>
      <!--- SET ACTION BAR END --->

      <!--- SET SHOW INFORMATION START--->
      <div class="vx-row mb-6 match-height">
        <div class="vx-col w-1/3">
          <vx-card class="w-full bg-dark flex items-center">
            <h3 class="font-bold">Bank Id</h3>
            <h3 class="font-light">{{ bankData.id }}</h3>
          </vx-card>
        </div>
        <div class="vx-col w-1/3">
          <vx-card class="w-full bg-dark flex items-center">
            <h3 class="font-bold">Bank Status</h3>
            <h3 class="capitalize font-light">{{ bankData.status }}</h3>
          </vx-card>
        </div>
        <div class="vx-col w-1/3">
          <vx-card class="w-full bg-dark flex items-center">
            <h3 class="font-bold">Bank Questions</h3>
            <h3 class="capitalize font-light">{{ bankData.questions.length }}</h3>
          </vx-card>
        </div>
      </div>
      <!--- SET SHOW INFORMATION END--->

      <!--- SET TERM ACTIONS START --->
      <div class="vx-row mb-6 match-height">
        <div class="vx-col w-1/3">
          <vx-card class="bg-dark">
            <vs-button
              color="success"
              type="gradient"
              class="w-full"
              icon-pack="feather"
              icon="icon-plus"
              @click="createQuestionPopup = true"
              >Create Question
            </vs-button>
          </vx-card>
        </div>
        <div class="vx-col w-1/3">
          <vx-card class="bg-dark">
            <vs-button
              color="warning"
              type="gradient"
              class="w-full"
              icon-pack="feather"
              icon="icon-file-plus"
              @click="addQuestionPopup = true"
              >Add Existing Question
            </vs-button>
          </vx-card>
        </div>
        <div class="vx-col w-1/3">
          <vx-card class="bg-dark">
            <vs-button
              color="primary"
              type="gradient"
              class="w-full"
              icon-pack="feather"
              icon="icon-upload"
              @click="$router.push(`/a/examedge/questions/upload?bank=${bankData.id}`)"
              >Upload Questions
            </vs-button>
          </vx-card>
        </div>
      </div>
      <!--- SET TERM ACTIONS END --->

      <!--- SET TERMS START --->
      <div class="vx-row">
        <div class="vx-col w-full">
          <list-questions
            title="Bank Questions"
            :bank-id="bankData.id"
            :filter-banks="false"
            :filter-button="false"
            :key="questionTableKey"
            v-on:refresh_data="fetchBankDetails(bankData.id)"
          />
        </div>
      </div>
      <!--- SET TERMS END --->

      <!--- CREATE TERM START --->
      <vs-popup :active.sync="createQuestionPopup" title="Create Question Values">
        <div class="popup-input">
          <p class="mb-1 font-semibold">Type</p>
          <v-select class="mb-3 w-full" :options="questionTypeOptions" v-model="createQuestionDetails.type" />

          <p class="mb-1 font-semibold">Question</p>
          <vs-textarea v-model="createQuestionDetails.question" height="250px" class="mb-3 w-full" />

          <vs-button color="success" class="mb-1" type="filled" @click="createQuestion">Create Question</vs-button>
        </div>
      </vs-popup>
      <!--- CREATE TERM END --->

      <!--- ADD TERM START --->
      <vs-popup :active.sync="addQuestionPopup" title="Search for Question">
        <vs-table :data="addQuestionResults" :sst="true" max-items="5" search @search="addQuestionSearch">
          <template slot="header">
            <h3 class="ml-4">Question Bank</h3>
          </template>

          <template slot="thead">
            <vs-th>Question</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach.id">
              <vs-td :data="attach.question">
                {{ attach.question.length > 200 ? attach.question.slice(0, 200) + '...' : attach.question }}
              </vs-td>

              <vs-td>
                <vs-button color="primary" size="small" type="border" @click="addQuestionToBank(attach.id)"> Assign </vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-popup>
      <!--- ADD TERM END --->
    </div>
    <div class="simple-spinner" v-else>
      <span></span>
    </div>
    <vs-popup :active.sync="editBankPopup" title="Edit Bank">
      <div class="popup-input" v-if="bankData">
        <p class="mb-1 font-semibold">Name</p>
        <vs-input v-model="bankData.name" class="mb-3 w-full" />

        <p class="mb-1 font-semibold">Description</p>
        <vs-textarea v-model="bankData.description" height="100px" class="mb-3 w-full" />

        <!-- <p class="mb-1 font-semibold">Thumbnail</p>
        <vs-input v-model="bankData.thumbnail" class="mb-3 w-full" /> -->

        <p class="mb-1 font-semibold">Status</p>
        <div class="flex items-center mb-3">
          <vs-radio v-model="bankData.status" vs-name="courseStatus" vs-value="active" class="mr-4">Active </vs-radio>
          <vs-radio v-model="bankData.status" vs-name="courseStatus" vs-value="disabled" class="mr-4">Disabled </vs-radio>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import ListQuestions from '../../questions/question-list/ListQuestions';

export default {
  data() {
    return {
      cardBgImgURL: 'https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/blue-wave-bg.png',

      editBankPopup: false,

      bankData: null,
      bankPracticeData: null,
      bankDescriptionCounter: false,
      errorOccurred: false,

      createQuestionPopup: false,
      createQuestionDetails: {
        question: null,
        type: null,
      },

      addQuestionPopup: false,
      addQuestionResults: '',

      questionTableKey: 0,
      questionTypeOptions: ['multiple_choice', 'select_choice'],
    };
  },
  components: {
    Prism,
    vSelect,
    ListQuestions,
  },
  methods: {
    fetchBankDetails(id) {
      this.$http
        .get(`examedge/bank/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.bankData = response.data;
          }
        })
        .catch(() => {
          this.errorOccurred = true;
        });
    },
    confirmDeleteBank() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Archive',
        text: 'Please confirm you want to archive this bank.',
        accept: this.deleteBank,
        acceptText: 'Archive',
      });
    },
    addQuestionSearch(question) {
      this.$http.get(`examedge/question?search=${question}&pageSize=3`).then((response) => {
        if (response.data) {
          this.addQuestionResults = response.data.rows;
        }
      });
    },
    addQuestionToBank(id) {
      if (this.bankData.questions.includes(id))
        return this.handleError('', 'Question is already inside of bank', 'You cannot add a question into a bank twice');
      this.$vs.loading();
      this.$http
        .patch(`examedge/bank/${this.bankData.id}/questions`, { addIds: [id] })
        .then((response) => {
          if (response.status === 200) {
            this.questionTableKey++;
            this.fetchBankDetails(this.bankData.id);
            this.$vs.loading.close();
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();
          this.handleError(exception, 'Failed to add question to bank');
        });
    },
    createQuestion() {
      this.$vs.loading();
      this.$http
        .post('examedge/question', this.createQuestionDetails)
        .then((response) => {
          if (response.status === 200) {
            this.createQuestionPopup = false;
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Created bank successfully',
              text: 'New question has been created.',
              color: 'success',
              position: 'top-right',
            });
            this.addQuestionToBank(response.data.id);
            this.createQuestionDetails = {
              question: '',
              bank: this.bankData.id,
            };
            this.$router.push(`/a/examedge/questions/${response.data.data.id}/edit`);
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();
          this.handleError(exception, 'Failed to create question');
        });
    },
    deleteBank() {
      this.$vs.loading();

      this.$http
        .delete(`testprep/banks/${this.$route.params.bankId}`)
        .then((response) => {
          this.$vs.loading.close();

          if (response.status === 200) {
            this.$router.push('/a/examedge/banks');

            return this.$vs.notify({
              color: 'success',
              title: 'Bank Archived',
              text: 'You have successfully archived this ban  ',
              icon: 'feather',
              iconPack: 'icon-check-circle',
              position: 'top-right',
            });
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();
          this.handleError(exception, 'Failed to archive bank');
        });
    },
    updateBank() {
      const pl = {
        ...this.bankData,
        questions: undefined,
      };
      this.$http
        .patch(`examedge/bank/${this.bankData.id}`, pl)
        .then((response) => {
          if (response.status === 200) {
            this.$vs.notify({
              title: 'Bank Updated Successfully',
              text: `${this.bankData.name} has been updated.`,
              color: 'success',
              position: 'bottom-right',
              iconPack: 'feather',
              icon: 'icon-check-circle',
            });
          }
        })
        .catch((exception) => {
          this.handleError(exception, 'Failed To Update Bank');
        });
    },
    handleError(exception, title, text) {
      let error = 'An unknown error occurred while bank update';
      if (exception.response) {
        error = exception.response.data.message;
      }
      if (text) {
        error = text;
      }
      return this.$vs.notify({
        title,
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
  created() {
    this.fetchBankDetails(this.$route.params.bankId);
    this.addQuestionSearch('');
  },
  watch: {
    editBankPopup(val) {
      if (!val) this.updateBank();
    },
  },
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-bank-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1700px) {
    .image-bank {
      display: none;
    }
    .bank-col {
      width: 0 !important;
      min-height: 2.05vh;
    }
    .welcome-col {
      width: 100% !important;
    }
  }
}
</style>
